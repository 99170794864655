export const UserRole = {
  super_admin: 0,
  area_manager: 1,
  location_manager: 2,
  employee: 3,
  customer: 4,
};

export const USER_TYPES = {
  SUPER_ADMIN: 'super_admin',
  ADMIN: 'area_manager',
  MANAGER: 'location_manager',
  EMPLOYEE: 'employee',
  CUSTOMER: 'customer',
};

export const SUPER_ADMIN = UserRole.super_admin;
export const ADMIN = UserRole.area_manager;
export const MANAGER = UserRole.location_manager;
export const EMPLOYEE = UserRole.employee;
export const CUSTOMER = UserRole.customer;

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden","menu-default"
*/
export const defaultMenuType = 'menu-sub-hidden';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/app';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

const userRole = localStorage.getItem('sessionUserRole');
// let Role = UserRole.Admin;

export const currentUser = {
  id: 1,
  title: 'Vishal Soni',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole[userRole], // userRole, // UserRole.Admin,
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
export const colorsCode = {
  bluenavy: '#00365a',
  blueyale: '#145388',
  blueolympic: '#008ecc',
  greenmoss: '#009434',
  greenlime: '#6fb327',
  purplemonster: '#6E247F',
  orangecarrot: '#ed7117',
  redruby: '#e60050',
  yellowgranola: '#c0a145',
  greysteel: '#48494b',
};
