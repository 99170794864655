/* eslint-disable no-restricted-globals */
import { useLocation } from 'react-router-dom';
import React from 'react';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from 'constants/defaultValues';
import { postData } from 'views/app/fetch-services';
import * as pdfjsLib from 'pdfjs-dist';

// Ensure the PDF worker is correctly set
pdfjsLib.GlobalWorkerOptions.workerSrc = `/js/pdf.worker.min.mjs`;

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('gogo_current_user') != null
        ? JSON.parse(localStorage.getItem('gogo_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('gogo_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('gogo_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function getPreviousTill2015() {
  let Arr = [];
  const date = new Date();
  let i = 0;
  while (!Arr.includes(2015)) {
    Arr.push(date.getFullYear() - i);
    i += 1;
  }
  Arr = Arr.map((item) => ({ label: item.toString(), value: item.toString() }));
  return Arr;
}
export const getAge = (date) => {
  let age;
  const countdownDate = new Date(date).getTime();
  if (countdownDate) {
    const currentTime = new Date().getTime();
    const distanceToDate = currentTime - countdownDate;
    let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);
    const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    if (numbersToAddZeroTo.includes(days)) {
      days = `0${days}`;
    }
    if (numbersToAddZeroTo.includes(hours)) {
      hours = `0${hours}`;
    }
    if (numbersToAddZeroTo.includes(minutes)) {
      minutes = `0${minutes}`;
    }
    if (numbersToAddZeroTo.includes(seconds)) {
      seconds = `0${seconds}`;
    }
    age = { days, hours, minutes };
  }
  return age;
};

export const fetchProfileImage = async () => {
  if (localStorage.getItem('jws_token')) {
    const result = await postData('users/getProfileImage', {
      user_id: localStorage.getItem('sessionUserId'),
    });
    if (result.success) {
      localStorage.setItem('profileImage', result.data);
    }
  }
};

export const checkFileType = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const { type } = blob;
    let fileType = '';
    // Check if the file is an image or video based on its MIME type
    if (type.startsWith('image/')) {
      fileType = 'image';
    } else if (type.startsWith('video/')) {
      fileType = 'video';
    }
    return fileType;
  } catch (error) {
    console.error('Error checking file type:', error);
    return 'Error';
  }
};

export const removeParentheses = (inputStr) => {
  // Use a regular expression to match the pattern inside parentheses
  const regex = /\([^)]+\)/g;
  // Replace all occurrences of the pattern with an empty string
  const resultStr = inputStr.replace(regex, '');
  return resultStr;
};

export const CommaFormatted = (amount = 0) => {
  const delimiter = ','; // replace comma if desired
  const amt = amount.toFixed(1).toString().split('.');
  const d = amt[1];
  let i = +amt[0]; // Convert string to number using unary plus
  if (Number.isNaN(i)) {
    return '';
  }
  let minus = '';
  if (i < 0) {
    minus = '-';
  }
  i = Math.abs(i);
  let n = i.toString(); // Convert to string using .toString()
  const a = [];
  while (n.length > 3) {
    const nn = n.substr(n.length - 3);
    a.unshift(nn);
    n = n.substr(0, n.length - 3);
  }
  if (n.length > 0) {
    a.unshift(n);
  }
  n = a.join(delimiter);
  return minus + n + (d ? `.${d}` : ''); // Include decimal part if it exists
};

export const allowedMimeTypes = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  // 'image/webp',
  // 'image/bmp',
  // 'image/svg+xml',
  // 'image/tiff',
  // 'image/ico',
  'video/mov', // MOV format
  'video/mp4', // MP4 format
  // 'video/webm', // WebM format
  // 'video/quicktime', // MOV format
  // 'video/x-matroska', // MKV format
  // 'video/ogg', // OGV format
];

export const fixDecimal = (number, decimalPlaces = 1) => {
  return parseFloat(number).toFixed(decimalPlaces);
};

export const companyHelpDeskPlans = [
  {
    label: 'Premium',
    value: 'premium',
  },
  {
    label: 'Lite',
    value: 'lite',
  },
  {
    label: 'Mix',
    value: 'mix',
  },
];

export const locationHelpDeskPlans = [
  {
    label: 'Premium',
    value: 'premium',
  },
  {
    label: 'Lite',
    value: 'lite',
  },
];

export const dashboardArr = [
  {
    value: 'customer',
    label: 'CUSTOMER',
  },
  {
    value: 'employee',
    label: 'EMPLOYEE',
  },
  {
    value: 'audit',
    label: 'AUDIT',
  },
  {
    value: 'survey',
    label: 'SURVEY',
  },
  {
    value: 'experience',
    label: 'EXPERIENCE',
  },
  {
    value: 'help-desk',
    label: 'HELPDESK',
  },
];

/**
 * Generates a thumbnail for the first page of a PDF.
 * @param {File|Blob} pdfFile - The PDF file to generate a thumbnail for.
 * @returns {Promise<string>} - A promise that resolves to the base64 image data URL.
 */
export const generatePdfThumbnail = async (pdfFile) => {
  try {
    let pdfBlob;

    // Check if the input is a File object
    if (pdfFile instanceof File) {
      pdfBlob = pdfFile; // Use the File directly
    } else {
      // Otherwise, assume it's a URL and fetch the PDF
      const response = await fetch(pdfFile);
      if (!response.ok) {
        throw new Error('Failed to fetch the PDF file');
      }
      pdfBlob = await response.blob(); // Get the PDF file as a Blob
    }

    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
      fileReader.onload = async function () {
        try {
          const typedArray = new Uint8Array(this.result);
          const pdf = await pdfjsLib.getDocument({ data: typedArray }).promise;
          const firstPage = await pdf.getPage(1);

          const viewport = firstPage.getViewport({ scale: 1 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          await firstPage.render({
            canvasContext: context,
            viewport,
          }).promise;

          const imageDataUrl = canvas.toDataURL();
          resolve(imageDataUrl); // Resolve with the generated thumbnail
        } catch (error) {
          console.error('Error generating PDF thumbnail:', error);
          reject(error);
        }
      };

      fileReader.readAsArrayBuffer(pdfBlob);
    });
  } catch (error) {
    console.error('Error in thumbnail generation function:', error);
    throw new Error('Failed to generate PDF thumbnail');
  }
};

export const daysSinceLastLogin = (lastLoginDate) => {
  if (!lastLoginDate) {
    return 0; // Return 0 days if no last login date is provided
  }

  const lastLogin = new Date(lastLoginDate); // Convert the last login date to a Date object
  if (isNaN(lastLogin)) {
    return 0; // Handle invalid date inputs gracefully
  }

  const currentDate = new Date(); // Get the current date
  const timeDifference = currentDate - lastLogin; // Difference in milliseconds
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

  return daysDifference;
};

export const formatTimeDifference = (createdAt) => {
  const now = new Date();
  const createdDate = new Date(createdAt);
  const diffMs = now - createdDate; // Difference in milliseconds
  const diffSeconds = Math.floor(diffMs / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);

  if (diffSeconds < 60) return 'Now';
  if (diffMinutes < 60) return `${diffMinutes} min ago`;
  if (diffHours < 24) return `${diffHours} hr${diffHours > 1 ? 's' : ''} ago`;
  if (diffDays <= 7) return `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
  return createdDate.toLocaleDateString(); // Fallback to specific date
};

export const getReportingPeriodLabel = () => {
  return localStorage.getItem('hasReportingPeriod') === 'true'
    ? localStorage.getItem('timePeriodLabel') ?? 'Last 7 Days'
    : localStorage.getItem('timePeriod') ?? 'Last 7 Days';
};
